import { Button } from 'antd';
import Router, { useRouter } from 'next/router';
import Link from 'next/link';

const Custom404 = () => {
  const router = useRouter();
  return (
    <div id='not-found'>
      <div className='main-content'>
        <div className='container'>
          <div className='inner'>
            <div className='image'>
              <img src='/assets/images/404.png' alt='' />
            </div>
            <h3>Không tìm thấy trang</h3>
            <Link href='/'>
              <a className='btn-back'>QUAY VỀ TRANG CHỦ</a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};



export default Custom404;